export const BASE_URL = 'https://rebrew.io' // redirects to www
export const BASE_URL_WWW = 'https://www.rebrew.io'
export const HOSTNAME = 'rebrew.io'
export const ROBUSTA_URL = 'https://robusta.rebrew.io'

export const absoluteHref = (route: string): string => {
  return BASE_URL_WWW + route // used as canonical
}

const ROUTES = {
  // Pages
  home: '/',

  // Auth
  signup: '/auth/signup',
  signUpRedirect: '/welcome',
  signin: '/auth/signin',
  signInRedirect: '/',
  welcome: '/welcome',

  // Settings
  settings: '/settings',
  settingsSocial: '/settings/socials',
  settingsWallets: '/settings/wallets',

  // Profile
  profile: (slug: string) => `/@${slug}`,

  // Collection
  collection: (username: string, slug: string) => `/@${username}/${slug}`,
  createCollection: '/collection/create',
  updateCollection: (id: string) => `/collection/update/${id}`,

  // Review
  review: (username: string, slug: string, id: string) => `/@${username}/${slug}/${id}`,

  // Blog
  blog: '/blog',
  blogPost: (slug: string) => `/blog/${slug}`,

  // Pages
  page: (slug: string) => `/page/${slug}`,
  terms: '/page/terms',
  privacy: '/page/privacy',
  forBusiness: '/business',
  
  // Explore
  explore: '/explore',

  // Redirects (used as external_urls in minting)
  redirectProfile: (id: string) => `${BASE_URL}/rp/${id}`,
  redirectCollection: (id: string) => `${BASE_URL}/rc/${id}`,
  redirectReview: (id: string) => `${BASE_URL}/rr/${id}`,

  // Revalidate (full routes)
  revalidateCollection: (username: string, slug: string) => `/collection/@${username}/${slug}`,
  revalidateReview: (username: string, slug: string, id: string) =>
    `/review/@${username}/${slug}/${id}`,
}

export const EXTERNAL_HREFS = {
  // Learn More
  defaultWallets: '#',
  nonCustodialWallets: '#',
  operationalWallets: '#',

  // External
  arweave: 'https://www.arweave.org/',
  bundlr: 'https://bundlr.network/',
  solana: 'https://solana.com/',
  metaplex: 'https://metaplex.com/',

  // Extra
  bookDemo: 'https://calendly.com/rebrew/rebrew',

  // Footer
  github: '#',
  twitter: 'https://twitter.com/rebrewio',
  twitterUsername: '@rebrewio',
  discord: 'https://www.rebrew.io/discord',
}

export default ROUTES
