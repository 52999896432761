import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

type ComponentProps = {
  children: React.ReactNode
  className?: string
}

const Component = ({ children, className }: ComponentProps) => {
  return (
    <div className={classnames('mx-auto px-2 sm:px-6 lg:px-8 w-full', className)}>{children}</div>
  )
}

Component.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}

export default Component
