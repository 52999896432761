import { PublicKey } from '@solana/web3.js'
import { connection } from 'config/solana'

const getBalance = async (publicKey: string): Promise<number | false> => {
  try {
    return await connection.getBalance(new PublicKey(publicKey))
  } catch (e) {
    return false
  }
}

export default getBalance
