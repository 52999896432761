import React, { useState, useEffect } from 'react'

export enum THEMES {
  Light = 'light',
  Dark = 'dark',
}

const getInitialTheme = (): string => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const storedPrefs = window.localStorage.getItem('theme')

    if (typeof storedPrefs === 'string') {
      return storedPrefs
    }

    const userMedia = window.matchMedia('(prefers-color-scheme: dark)')

    if (userMedia.matches) {
      return THEMES.Dark
    }
  }

  return THEMES.Light
}

type ThemeContextProps = {
  theme: string
  setTheme: (theme: THEMES) => void
}

export const ThemeContext = React.createContext<ThemeContextProps>({
  theme: THEMES.Light,
  setTheme: () => null,
})

type ThemeProviderProps = {
  children: React.ReactNode
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [theme, setTheme] = useState(getInitialTheme())

  useEffect(() => {
    window.localStorage.setItem('theme', theme)
  }, [theme])

  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>
}
