import React from 'react'

type RootContextType = {}

export const RootContext = React.createContext<RootContextType | null>(null)

type RootProviderProps = {
  children: React.ReactNode
}

export const RootProvider = ({ children }: RootProviderProps) => {
  return <RootContext.Provider value={null}>{children}</RootContext.Provider>
}
