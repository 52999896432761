import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

type ComponentProps = {
  title: React.ReactNode
  className?: string
  innerClassName?: string
}

const Component = ({ title, className, innerClassName }: ComponentProps) => {
  return (
    <div className={classnames('h-36 bg-gray-50 flex flex-col justify-center', className)}>
      <div className={classnames('mx-auto px-2 sm:px-6 lg:px-8 w-full', innerClassName)}>
        <h1 className="text-2xl font-medium">{title}</h1>
      </div>
    </div>
  )
}

Component.propTypes = {
  title: PropTypes.any,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
}

export default Component
