import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useAuth from 'hooks/useAuth'
import useSolanaBalance from 'hooks/useSolanaBalance'

import { formatSolanaBalance } from 'utils/format'

type UserMenuLinkBalanceType = {}

const UserMenuLinkBalance = ({}: UserMenuLinkBalanceType) => {
  const { t } = useTranslation('common')

  const { user } = useAuth()
  const { balance, isLoading, revalidateBalance, isValidating } = useSolanaBalance()

  if (!user) {
    return null
  }

  return (
    <div
      className="flex items-center justify-between w-full"
      onClick={() => {
        if (isLoading === false && isValidating === false) {
          revalidateBalance()
        }
      }}
    >
      <div>
        <div className="mb-1">{t('navbar.userMenu.balance')}</div>
        <div className="text-gray-400 text-sm">{formatSolanaBalance(balance || 0)} SOL</div>
      </div>

      <div>{(isLoading || isValidating) && <FontAwesomeIcon spin icon={['far', 'loader']} />}</div>
    </div>
  )
}

UserMenuLinkBalance.propTypes = {
  //
}

export default UserMenuLinkBalance
