import React from 'react'
import Image from 'next/image'
import PropTypes from 'prop-types'
import classnames from 'classnames'

type ComponentProps = {
  src: string
  size: number
  alt: string
  borderSize?: number
  borderColor?: string
  roundClassname?: string
}

const RawAvatar = ({
  src,
  size,
  borderSize,
  borderColor,
  alt,
  roundClassname = 'rounded-full',
}: ComponentProps) => {
  return (
    <div
      className={classnames(
        'shrink-0 box-border',
        {
          'border-8': borderSize === 8,
          'border-white': borderColor === 'white',
        },
        roundClassname,
      )}
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <Image
        className={classnames(roundClassname)}
        alt={alt}
        width={size}
        height={size}
        src={src}
      />
    </div>
  )
}

RawAvatar.propTypes = {
  size: PropTypes.number,
  borderSize: PropTypes.number,
  borderColor: PropTypes.string,
  alt: PropTypes.string,
  src: PropTypes.string,
  roundClassname: PropTypes.string,
}

export default RawAvatar
