export const SITE_NAME = 'Rebrew'
export const SITE_STAGE = 'Beta'

export const SYMBOL = 'RBRW'

export enum SOCIAL_COLORS {
  github = '#171515',
  twitter = '#1DA1F2',
  facebook = '#0165E1',
  reddit = '#ff4500',
  linkedin = '#0072b1',
  telegram = '#229ED9',
  whatsapp = '#25D366',
  discord = '#5865F2',
  solana = '#000',
  phantom = '#10131F',
  solflare = '#10131F',
  torus = '#10131F',
  glow = '#10131F',
}

export enum COLLECTION_TYPES {
  companyReviews = 'company-reviews',
  productReviews = 'product-reviews',
  featureFeedback = 'feature-feedback',
  other = 'other',
}

export enum ACCOUNT_TYPES {
  individual,
  company,
}

export enum REVIEW_TYPES {
  negative = -1,
  neutral = 0,
  positive = 1,
}

export const REVIEW_EMOJIS = {
  [REVIEW_TYPES.negative]: '😡',
  [REVIEW_TYPES.positive]: '😍',
  [REVIEW_TYPES.neutral]: '😐',
}

export enum REVIEW_REACTIONS {
  negative = -1,
  positive = 1,
}

export enum USER_ROLES {
  default = '',
  admin = 'admin',
}

export enum ARWEAVE_TRANSACTION_TYPES {
  funding,

  collectionImage,
  collectionMetadata,

  reviewImage,
  reviewVideo,
  reviewMetadata,
}

export enum SOLANA_TRANSACTION_TYPES {
  mintCollection,
  mintReview,
  updateReview,
}

export enum HOME_BOARD_IDS {
  exchanges = 'cl6p0x00b000209mhg9mba487',
  nftMarketplaces = 'cl6p3ldqt000109l2cr6sax5l',
  other = 'cl6p3mkxc000209l26b3x0m3d',
}

export const DEFAULT_NAME = 'Anonymous'

export const LIMIT_REVIEW_IMAGES = 3
