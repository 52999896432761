import { useEffect, useState } from 'react'

enum TailwindSizes {
  sm = 640,
  md = 768,
  lg = 1024,
  xl = 1280,
  xl2 = 1536,
}

const useViewport = () => {
  const [width, setWidth] = useState(0)

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)

    window.addEventListener('resize', handleWindowResize)

    handleWindowResize()

    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return {
    width,
    isSmall: width <= TailwindSizes.sm,
    isMedium: width > TailwindSizes.sm && width <= TailwindSizes.md,
    isLarge: width > TailwindSizes.md && width <= TailwindSizes.lg,
    isXl: width > TailwindSizes.lg && width <= TailwindSizes.xl,
    isXl2: width > TailwindSizes.xl,
  }
}

export default useViewport
