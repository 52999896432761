import React from 'react'
import PropTypes from 'prop-types'

import Title from './Title'
import Container from './Container'

type ComponentProps = {
  title?: React.ReactNode
  children?: React.ReactNode
  containerClassname?: string
  titleClassname?: string
  titleInnerClassname?: string
  showTitle?: boolean
}

const Component = ({
  title,
  children,
  containerClassname,
  titleClassname,
  titleInnerClassname,
  showTitle = true,
}: ComponentProps) => {
  return (
    <>
      {showTitle && title && (
        <Title className={titleClassname} innerClassName={titleInnerClassname} title={title} />
      )}

      <Container className={containerClassname}>{children}</Container>
    </>
  )
}

Component.propTypes = {
  title: PropTypes.any,
  children: PropTypes.any,
  containerClassname: PropTypes.string,
  titleClassname: PropTypes.string,
  titleInnerClassname: PropTypes.string,
  showTitle: PropTypes.bool,
}

export default Component
