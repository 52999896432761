import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import { Connection, clusterApiUrl } from '@solana/web3.js'

const getSolanaNetwork = () => {
  if (process.env.NEXT_PUBLIC_SOLANA_NETWORK === 'devnet') {
    return WalletAdapterNetwork.Devnet
  } else if (process.env.NEXT_PUBLIC_SOLANA_NETWORK === 'mainnet') {
    return WalletAdapterNetwork.Mainnet
  }

  throw new Error('NEXT_PUBLIC_SOLANA_NETWORK is not defined')
}

const getSolanaNetworkURL = () => {
  if (process.env.NEXT_PUBLIC_SOLANA_NETWORK === 'devnet') {
    return clusterApiUrl(WalletAdapterNetwork.Devnet)
  } else if (process.env.NEXT_PUBLIC_SOLANA_NETWORK === 'mainnet') {
    const nodeUrl =
      process.env.SOLANA_BACKEND_NODE_URL || process.env.NEXT_PUBLIC_SOLANA_FRONTEND_NODE_URL

    if (!nodeUrl) {
      throw new Error(
        'SOLANA_BACKEND_NODE_URL or NEXT_PUBLIC_SOLANA_FRONTEND_NODE_URL is not defined',
      )
    }

    return nodeUrl
  }

  throw new Error('NEXT_PUBLIC_SOLANA_NETWORK is not defined')
}

export const NETWORK = getSolanaNetwork()

export const NETWORK_URL = getSolanaNetworkURL()

export const connection = new Connection(NETWORK_URL)
