import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { Menu, MenuDivider } from '@szhsin/react-menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useAuth from 'hooks/useAuth'

// Local Components
import UserMenuButton from './UserMenuButton'
import UserMenuLink from './UserMenuLink'
import UserMenuLinkProfile from './UserMenuLinkProfile'
import UserMenuLinkBalance from './UserMenuLinkBalance'
import ROUTES from 'config/routes'
import useViewport from 'hooks/useViewport'

type UserMenuItemType =
  | 'profile'
  | 'logout'
  | 'divider'
  | 'settings'
  | 'newCollection'
  | 'balance'
  | {
      href: string
      label: any
    }

type UserMenuType = {
  items?: UserMenuItemType[]
  onlyAvatar?: boolean
  direction?: 'bottom' | 'left' | 'right' | 'top'
  isWhite?: boolean
  isBlack?: boolean
  isGray?: boolean
}

const UserMenu = ({
  items = [],
  onlyAvatar = false,
  direction = 'bottom',
  isWhite = false,
  isBlack = false,
  isGray = false,
}: UserMenuType) => {
  const { t } = useTranslation('common')

  const router = useRouter()

  const { isGuest, signOut, user } = useAuth()

  const { isSmall } = useViewport()

  const [display] = useState('arrow')

  if (isGuest || !user) {
    return null
  }

  const defaultItems: UserMenuItemType[] = user.isCompany
    ? ['profile', 'balance', 'newCollection', 'settings', 'divider', 'logout']
    : ['profile', 'balance', 'settings', 'divider', 'logout']

  // use default items if not provided
  const finalItems: UserMenuItemType[] = items.length > 0 ? items : defaultItems

  return (
    <div role={'menu'}>
      <Menu
        menuButton={
          <UserMenuButton
            user={user}
            onlyAvatar={onlyAvatar}
            isWhite={isWhite}
            isBlack={isBlack}
            isGray={isGray}
          />
        }
        menuClassName="p-4 rounded-xl w-52 shadow-2xl bg-white ring-1 ring-black/5"
        key={direction}
        direction={direction}
        align="center"
        position="anchor"
        viewScroll="auto"
        boundingBoxPadding={isSmall ? '10' : '30'}
        arrow
        offsetX={display === 'offset' && (direction === 'left' || direction === 'right') ? 12 : 0}
        offsetY={display === 'offset' && (direction === 'top' || direction === 'bottom') ? 12 : 0}
      >
        {finalItems.map((item, idx) => {
          if (item === 'divider') {
            return <MenuDivider className={'bg-gray-100'} key={`divider-${idx}`} />
          }

          if (item === 'logout') {
            return (
              <UserMenuLink
                key={`signout-${idx}`}
                onClick={async () => {
                  await signOut()
                  router.push(ROUTES.home)
                }}
              >
                {t('navbar.userMenu.signOut')}
              </UserMenuLink>
            )
          }

          if (item === 'profile') {
            return (
              <UserMenuLink key={`profile-${idx}`} href={ROUTES.profile(user.username)}>
                <UserMenuLinkProfile user={user} />
              </UserMenuLink>
            )
          }

          if (item === 'balance') {
            return (
              <UserMenuLink className="cursor-pointer" asDiv key={`balance-${idx}`}>
                <UserMenuLinkBalance />
              </UserMenuLink>
            )
          }

          if (item === 'newCollection') {
            return (
              <UserMenuLink
                key={`new-collection-${idx}`}
                href={ROUTES.createCollection}
                className="flex items-center justify-between"
              >
                <span>{t('navbar.userMenu.newCollection')}</span>
                <FontAwesomeIcon icon={['far', 'plus']} />
              </UserMenuLink>
            )
          }

          if (item === 'settings') {
            return (
              <UserMenuLink key={`settings-${idx}`} href={ROUTES.settings}>
                {t('navbar.userMenu.settings')}
              </UserMenuLink>
            )
          }

          return (
            <UserMenuLink key={`link-${idx}`} onClick={() => router.push(item.href)}>
              {item.label}
            </UserMenuLink>
          )
        })}
      </Menu>
    </div>
  )
}

UserMenu.propTypes = {
  onlyAvatar: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        type: PropTypes.oneOf(['logout', 'profile', 'divider', 'settings', 'newCollection']),
        href: PropTypes.string,
        label: PropTypes.any,
      }),
    ]),
  ),
  direction: PropTypes.oneOf(['bottom', 'top', 'left', 'right']),
  isWhite: PropTypes.bool,
  isBlack: PropTypes.bool,
  isGray: PropTypes.bool,
}

export default UserMenu
