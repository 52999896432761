import React from 'react'

/**
 * Hook for getting previous value
 */
function usePrevious(value: any) {
  const ref = React.useRef(value)

  React.useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export default usePrevious
