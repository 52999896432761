import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import UserAvatar from 'components/UserAvatar'
import UserAuth from 'utils/classes/UserAuth'

type UserMenuLinkProfileLabelType = {
  user: UserAuth
}

const UserMenuLinkProfileLabel = ({ user }: UserMenuLinkProfileLabelType) => {
  const { t } = useTranslation('common')

  return (
    <div className="flex items-center justify-between w-full">
      <span>{t('navbar.userMenu.profile')}</span>
      <UserAvatar size={30} user={user} />
    </div>
  )
}

UserMenuLinkProfileLabel.propTypes = {
  user: PropTypes.any,
}

export default UserMenuLinkProfileLabel
