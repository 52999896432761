import React from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'

import { BASE_URL_WWW, EXTERNAL_HREFS } from 'config/routes'
import { SITE_NAME } from 'config/constants'

type OpenGraphProps = {
  type?: 'article' | 'website' | 'profile'
  title?: string
  description?: string
  url?: string
  image?: string
  siteName?: string
}

type TwitterProps = {
  title?: string
  description?: string
  image?: string
}

type ComponentProps = {
  title: string
  description: string
  canonical: string
  og?: OpenGraphProps
  twitter?: TwitterProps
}

const HeadSeo = ({ title, description, og, canonical, twitter }: ComponentProps) => {
  const defaultImage = `${BASE_URL_WWW}/logo/social/logoOnWhite1000.png`

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonical} />

      {/* OPENGRAPH */}
      <meta property="og:type" content={(og && og.type) || 'website'} />
      <meta property="og:title" content={(og && og.title) || title} />
      <meta property="og:description" content={(og && og.description) || description} />
      <meta property="og:site_name" content={(og && og.siteName) || SITE_NAME} />
      <meta property="og:url" content={(og && og.url) || canonical} />

      {og && og.image ? (
        <>
          <meta property="og:image" content={og.image} />
        </>
      ) : (
        <>
          <meta property="og:image" content={defaultImage} />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1000" />
          <meta property="og:image:height" content="1000" />
        </>
      )}

      {/* TWITTER */}
      <meta name="twitter:title" content={(twitter && twitter.title) || title} />
      <meta name="twitter:description" content={(twitter && twitter.description) || description} />
      <meta name="twitter:image" content={(twitter && twitter.image) || defaultImage} />
      <meta name="twitter:site" content={EXTERNAL_HREFS.twitterUsername} />
    </Head>
  )
}

HeadSeo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  canonical: PropTypes.string,
  og: PropTypes.object,
  twitter: PropTypes.object,
}

export default HeadSeo
