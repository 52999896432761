import { useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'

type ComponentProps = {
  href: string
  isReplace?: boolean
}

const RedirectComp = ({ href, isReplace = false }: ComponentProps) => {
  const router = useRouter()

  useEffect(() => {
    if (isReplace) {
      router.replace(href)
    } else {
      router.push(href)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

RedirectComp.propTypes = {
  href: PropTypes.string,
  isReplace: PropTypes.bool,
}

const Redirect = memo(RedirectComp, () => true)

Redirect.displayName = 'Redirect'

export default Redirect
