import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useRouter } from 'next/router'

import Link from 'components/Link'
import LogoBeans from 'components/Logo/Beans'
import UserMenu from 'components/UserMenu'

import GoBackButton from './GoBackButton'
import Container from './Container'

type ComponentProps = {
  link?: string
  linkLabel?: string
  children: React.ReactNode
  showUserMenu?: boolean
  onClickGoBack?: () => void
}

const Component = ({ children, link, linkLabel, showUserMenu, onClickGoBack }: ComponentProps) => {
  const router = useRouter()

  return (
    <div className={classnames('flex items-center h-full')}>
      <div className={classnames('h-full w-full lg:w-2/3 relative', 'bg-white')}>
        <GoBackButton onClick={() => (onClickGoBack ? onClickGoBack() : router.back())} />

        {link && linkLabel && (
          <Link
            href={link}
            className={
              'btn btn-sm btn-white w-24 absolute right-0 top-0 mr-5 lg:mr-8 mt-5 lg:mt-8 lg:hidden'
            }
          >
            {linkLabel}
          </Link>
        )}

        <Container>{children}</Container>
      </div>

      <div className={classnames('h-full w-1/3 fixed right-0 hidden lg:block', 'bg-gradient')}>
        {showUserMenu && (
          <div className="absolute right-0 top-0 mr-5 lg:mr-8 mt-5 lg:mt-8 z-10">
            <UserMenu isBlack />
          </div>
        )}

        {link && linkLabel && (
          <Link
            href={link}
            className={
              'btn none btn-sm btn-white w-24 absolute right-0 top-0 mr-5 lg:mr-8 mt-5 lg:mt-8 z-10'
            }
          >
            {linkLabel}
          </Link>
        )}

        <div className="absolute bottom-0 left-0 right-0 mb-12">
          <LogoBeans width={150} height={150} />
        </div>
      </div>
    </div>
  )
}

Component.propTypes = {
  children: PropTypes.any,
  link: PropTypes.string,
  linkLabel: PropTypes.string,
  showUserMenu: PropTypes.bool,
  onClickGoBack: PropTypes.func,
}

export default Component
