import React from 'react'

type ContainerProps = {
  children?: React.ReactNode
}

const Container = ({ children }: ContainerProps) => {
  return (
    <div className={'relative max-w-md pb-6 mt-6 lg:-mt-6 2xl:mt-6 mx-auto px-6 sm:px-0'}>
      {children}
    </div>
  )
}

export default Container
