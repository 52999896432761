import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import UserAuth from 'utils/classes/UserAuth'

import UserAvatar from 'components/UserAvatar'

type UserMenuButtonProps = {
  user: UserAuth
  onlyAvatar?: boolean
  isWhite?: boolean
  isBlack?: boolean
  isGray?: boolean
}

const UserMenuButton = forwardRef<HTMLButtonElement, UserMenuButtonProps>(
  ({ user, onlyAvatar, isWhite, isBlack, isGray, ...rest }: UserMenuButtonProps, ref) => {
    return (
      <button
        ref={ref}
        className={classnames('flex items-center focus:outline-none', {
          'text-md rounded-full mr-2 sm:mr-0 sm:p-2 sm:pr-6': onlyAvatar !== true,
          'bg-white text-black': isWhite === true && onlyAvatar !== true,
          'bg-gray-100 text-black': isGray === true && onlyAvatar !== true,
          'bg-gray-800 text-white': isBlack === true && onlyAvatar !== true,
        })}
        {...rest}
      >
        <UserAvatar size={35} user={user} />

        {onlyAvatar !== true && (
          <span className={'ml-3 hidden sm:block font-semibold text-sm'}>
            {user.publicKeyShort}
          </span>
        )}
      </button>
    )
  },
)

UserMenuButton.propTypes = {
  user: PropTypes.any,
  onlyAvatar: PropTypes.bool,
  isWhite: PropTypes.bool,
  isBlack: PropTypes.bool,
  isGray: PropTypes.bool,
}

UserMenuButton.displayName = 'UserMenuButton'

export default UserMenuButton
