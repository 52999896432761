import React from 'react'
import PropTypes from 'prop-types'
import Avvvatars from 'avvvatars-react'

import RawAvatar from './RawAvatar'

import UserAuth from 'utils/classes/UserAuth'
import UserPublic from 'utils/classes/UserPublic'
import ReviewCreatedByUser from 'utils/classes/ReviewCreatedByUser'

interface Params {
  displayValue?: string
  size: number
  shadow?: boolean
  border?: boolean
  borderSize?: number
  borderColor?: string
  radius?: number
}

interface ComponentProps extends Params {
  user: UserAuth | UserPublic | ReviewCreatedByUser
}

const UserAvatar = ({ user, ...rest }: ComponentProps) =>
  user.avatarPublicUrl ? (
    <RawAvatar
      src={user.avatarPublicUrl}
      alt={user.name || user.username || user.publicKey}
      size={rest.size}
      borderSize={rest.borderSize}
      borderColor={rest.borderColor}
    />
  ) : (
    <div className="shrink-0">
      <Avvvatars style="shape" value={user.publicKey} {...rest} />
    </div>
  )

UserAvatar.propTypes = {
  user: PropTypes.any,
}

export default UserAvatar
