import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ROUTES from 'config/routes'
import Image from 'next/image'
import Link from 'components/Link'

type LogoType = {
  height?: number
  width?: number
  className?: any
  showVersion?: boolean
  showCommit?: boolean
}

const Logo = ({ height = 150, width = 150, className }: LogoType) => {
  return (
    <Link
      href={ROUTES.home}
      className={classnames(
        'flex items-center justify-center cursor-pointer relative hover:hue-rotate-180 duration-200',
        className,
      )}
    >
      <Image
        quality={100}
        src="/logo/square/solana750.png"
        alt="Logo"
        width={width}
        height={height}
      />
    </Link>
  )
}

Logo.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.any,
}

export default Logo
