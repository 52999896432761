import React from 'react'
import PropTypes from 'prop-types'
import { MenuItem } from '@szhsin/react-menu'
import classnames from 'classnames'

import Link from 'components/Link'
import classNames from 'classnames'

type UserMenuLinkType = {
  children: any
  href?: string
  onClick?: () => void
  className?: string
  asDiv?: boolean
}

const UserMenuLink = ({ onClick, href, children, className, asDiv = false }: UserMenuLinkType) => {
  const classNameCompiled = classnames(
    {
      'px-4 rounded-xl py-3 text-sm text-gray-800 hover:bg-gray-100 hover:text-black': true,
      'duration-100 transform-gpu': true,
    },
    className,
  )

  if (asDiv) {
    return (
      <div className={classNameCompiled} onClick={onClick}>
        {children}
      </div>
    )
  }

  if (href) {
    return (
      <Link href={href} className={classNames(classNameCompiled, 'block')}>
        {children}
      </Link>
    )
  }

  return (
    <MenuItem className={classNameCompiled} onClick={onClick}>
      {children}
    </MenuItem>
  )
}

UserMenuLink.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.string,
  asDiv: PropTypes.bool,
}

export default UserMenuLink
