export const formatSolanaBalance = (balance: number, decimals = 4): string => {
  return `${1 * parseFloat(balance.toFixed(decimals))}`
}

/**
 * 1. Will remove all mentions in the beginning
 * 2. Will remove initial hashtag if present in the sentence
 * 3. Will remove https://t.co/xxxx links (actually remove any links)
 * 4. Will remove all mentions in the end of the tweet
 * 5. Will remove all hashtags in the end of the tweet
 */
export const formatTweetText = (text: string): string => {
  let modified = text.trim()

  while (modified.charAt(0) === '@') {
    const words = modified.split(' ')
    words.shift()
    modified = words.join(' ')
  }

  if (modified.charAt(0) === '#') {
    modified = modified.substring(1)
  }

  modified = modified.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '')
  modified = modified.trim()

  modified = modified.replace('\n\n', '\n\n ') // for special cases - the world.\n\n@Mohamma90130066

  const reversedWords = modified.split(' ').reverse()

  for (const lastWord of reversedWords) {
    if (
      lastWord.length === 0 || // is weird space
      lastWord === '\n' || // is weird empty break
      lastWord.startsWith('\n@') || // is weird mention
      lastWord.startsWith('\n#') || // is weird hashtag
      lastWord.charAt(0) === '@' || // is mention
      lastWord.charAt(0) === '#' // is hashtag
    ) {
      // remove last word
      modified = modified.substring(0, modified.lastIndexOf(' '))
    } else {
      // if last word is not a mention or hashtag
      break
    }
  }

  modified = modified.trim()

  if (modified.length === 0) {
    modified = 'Unknown Twitter error' // this shouldn't happen but just in case
  }

  return modified
}
