import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CircleButton from 'components/Button/CircleButton'

type GoBackButtonType = {
  onClick: () => void
}

const GoBackButton = ({ onClick }: GoBackButtonType) => {
  return (
    <CircleButton className={'bg-white ml-5 lg:ml-8 mt-5 lg:mt-8'} onClick={onClick}>
      <FontAwesomeIcon className={'text-gray-600'} icon={['fal', 'chevron-left']} />
    </CircleButton>
  )
}

export default GoBackButton
