import useSWR from 'swr'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'

import getBalance from 'libs/solana/getBalance'
import useAuth from './useAuth'

const fetchSolanaBalance = async (key: 'getMyOperationalPublicKeyBalance', publicKey: string) => {
  if (!publicKey) {
    return null // wait for operational public key
  }

  const result = await getBalance(publicKey)

  if (result === false) {
    throw new Error(`An error occurred while fetching the balance for ${publicKey}.`)
  }

  return result
}

const useSolanaBalance = () => {
  const { user } = useAuth()

  const publicKey = user?.operationalPublicKey

  const { data, error, mutate, isValidating } = useSWR(
    ['getMyOperationalPublicKeyBalance', publicKey],
    fetchSolanaBalance,
    {
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
    },
  )

  const revalidateBalance = () => mutate()

  return {
    balanceLamports: data,
    balance: data ? data / LAMPORTS_PER_SOL : null, // solana balance
    isLoading: !error && !data,
    error,
    revalidateBalance,
    isValidating,
  }
}

export default useSolanaBalance
