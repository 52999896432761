import supabase from 'config/supabase'

import { ACCOUNT_TYPES, USER_ROLES } from 'config/constants'

/**
 * Supabase returns snake case data. We can not use prisma
 */
class UserAuth {
  private user: any

  constructor(user: any) {
    this.user = user
  }

  get id(): string {
    return this.user.id
  }

  get name(): string {
    return this.user.name
  }

  get email(): string | null {
    return this.user.email
  }

  get website(): string | null {
    return this.user.website
  }

  get bio(): string | null {
    return this.user.bio
  }

  get emailVerified(): Date | null {
    return this.user.email_verified
  }

  get publicKey(): string {
    return this.user.public_key
  }

  get username(): string {
    return this.user.username
  }

  get accountType(): ACCOUNT_TYPES {
    return this.user.account_type
  }

  get isIndividual(): boolean {
    return this.accountType === ACCOUNT_TYPES.individual
  }

  get isCompany(): boolean {
    return this.accountType === ACCOUNT_TYPES.company
  }

  get operationalPublicKey(): string {
    return this.user.operational_public_key
  }

  get operationalPublicKeyWithDots(): string {
    const first = this.operationalPublicKey.substring(0, 4)
    const last = this.operationalPublicKey.slice(-4)
    return first + '...' + last
  }

  get isWalletNonCustodial(): boolean {
    return this.isWalletCustodial === false
  }

  get isWalletCustodial(): boolean {
    return this.user.is_custodial
  }

  get publicKeyShort(): string {
    return this.publicKey.substring(0, 4)
  }

  get publicKeyWithDots(): string {
    const first = this.publicKey.substring(0, 4)
    const last = this.publicKey.slice(-4)
    return first + '...' + last
  }

  get twitterUsername(): string | null {
    return this.user.twitter_username
  }

  get discordUsername(): string | null {
    return this.user.discord_username
  }

  get githubUsername(): string | null {
    return this.user.github_username
  }

  get githubLink(): string | null {
    return this.user.github_link
  }

  get discordLink(): string | null {
    return this.user.discord_link
  }

  get twitterLink(): string | null {
    return this.user.twitter_link
  }

  get instagramLink(): string | null {
    return this.user.instagram_link
  }

  get telegramLink(): string | null {
    return this.user.telegram_link
  }

  get claimed(): Date | null {
    return this.user.claimed_at
  }

  get hasChangedUsername(): boolean {
    return this.username !== this.publicKey
  }

  get avatarPublicUrl(): string | undefined {
    if (this.user.avatar) {
      // custom uploaded avatar
      const data = supabase.storage.from('avatars').getPublicUrl(this.user.avatar)

      if (data.publicURL) {
        return data.publicURL
      }
    }

    if (this.user.image) {
      // oauth2 image
      // todo install sharp and proxify images
      //return this.user.image
    }
  }

  get positiveReviewReactions(): string[] {
    return this.user.positive_review_reactions || []
  }

  get negativeReviewReactions(): string[] {
    return this.user.negative_review_reactions || []
  }

  get isAdmin(): boolean {
    return this.user.role === USER_ROLES.admin
  }
}

export default UserAuth
