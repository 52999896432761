import { useContext, useEffect } from 'react'
import { AuthContext } from 'components/Context/Supabase'
import { useSession, signOut as nextAuthSignOut } from 'next-auth/react'
import supabase from 'config/supabase'
import UserSession from 'utils/classes/UserSession'
import User from 'utils/classes/UserAuth'
import usePrevious from './usePrevious'
import useSWR from 'swr'

const getMeFetcher = async (key: 'getMe', supaJWT?: string) => {
  if (!supaJWT) {
    return null // wait for supabase JWT propagation before fetching user auth
  }

  const result = await supabase.rpc('get_current_user_data')

  if (result.status !== 200 || !result.data) {
    const error = new Error(`An error occurred while fetching the ${key} data.`)
    throw error
  }

  return result.data[0]
}

const useAuth = () => {
  const supabaseContext = useContext(AuthContext)
  const { data: session, status } = useSession()

  // Supabase JWT are stored in Rebrew JWT
  const sessionSupabaseJWT = session?.user.supJWT
  const prevSessionSupabaseJWT = usePrevious(sessionSupabaseJWT)
  const currentSupabaseJWT = supabaseContext.session?.access_token // real current used JWT

  const { data: userData, mutate } = useSWR(['getMe', currentSupabaseJWT], getMeFetcher)

  if (supabaseContext === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  /**
   * Detect supabase JWT changes and update auth
   */
  useEffect(() => {
    if (sessionSupabaseJWT !== prevSessionSupabaseJWT && sessionSupabaseJWT) {
      supabase.auth.setAuth(sessionSupabaseJWT)
    }
  }, [sessionSupabaseJWT, prevSessionSupabaseJWT])

  /**
   * First sign out from supabase
   * Then from the app
   */
  const signOut = async () => {
    await supabaseContext.signOut()

    return await nextAuthSignOut({ redirect: false, callbackUrl: '/' })
  }

  const revalidateUser = () => mutate()

  return {
    isAuthLoading: status === 'loading',
    isGuest: status === 'unauthenticated' || status === 'loading',
    isAuthenticated: status === 'authenticated',
    expires: session?.expires,
    session: session ? new UserSession(session.user) : null,
    signOut,
    user: userData ? new User(userData) : null,
    supabaseSession: supabaseContext.session,
    revalidateUser,
  }
}

export default useAuth
