import React, { useContext, useEffect } from 'react'
import { RootProvider } from '../Context/Root'
import { ThemeContext, ThemeProvider, THEMES } from '../Context/Theme'
import Head from 'next/head'
import SolanaWalletProvider from '../Context/Solana'
import { Toaster } from 'react-hot-toast'

import classnames from 'classnames'

export type RootHelmetProps = {
  isHeightFull?: boolean
  useDarkMode?: boolean
  htmlClassname?: string
  bodyClassName?: string
}

const RootHelmet = ({
  isHeightFull,
  useDarkMode,
  htmlClassname,
  bodyClassName,
}: RootHelmetProps) => {
  const { theme } = useContext(ThemeContext)

  useEffect(() => {
    const body = document.querySelector('body')

    if (body) {
      body.className = classnames(
        {
          'bg-white dark:bg-gray-900': typeof bodyClassName === 'undefined',
          'h-full': isHeightFull === true,
        },
        bodyClassName,
      )
    }
  }, [bodyClassName, isHeightFull])

  useEffect(() => {
    const html = document.querySelector('html')

    if (html) {
      html.setAttribute('lang', 'en-US')

      html.className = classnames(
        {
          'h-full': isHeightFull === true,
          dark: theme === THEMES.Dark && useDarkMode === true,
        },
        htmlClassname,
      )
    }
  }, [isHeightFull, htmlClassname, theme, useDarkMode])

  return (
    <Head>
      <meta charSet="utf-8" key="charSet" />

      {/* FAVICONS */}
      <link rel="icon" type="image/x-icon" href="/favicon.ico" key="favicon" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />

      {/* DEFAULTS */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Head>
  )
}

export type RootProps = {
  isHeightFull?: boolean
  useDarkMode?: boolean
  htmlClassname?: string
  bodyClassName?: string
  children: React.ReactNode
}

const Root = ({ children, ...rest }: RootProps) => {
  return (
    <RootProvider>
      <ThemeProvider>
        <SolanaWalletProvider>
          <RootHelmet {...rest} />

          {children}

          <Toaster />
        </SolanaWalletProvider>
      </ThemeProvider>
    </RootProvider>
  )
}

export default Root
