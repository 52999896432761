import { SessionUser } from 'next-auth'

import { USER_ROLES } from 'config/constants'

class UserSession {
  private user: SessionUser

  constructor(user: SessionUser) {
    this.user = user
  }

  get id(): string {
    return this.user.id
  }

  get publicKey(): string {
    return this.user.pk
  }

  get supabaseId(): string {
    return this.user.supId
  }

  get isAdmin(): boolean {
    return this.user.role === USER_ROLES.admin
  }

  get isWalletNonCustodial(): boolean {
    return this.isWalletCustodial === false
  }

  get isWalletCustodial(): boolean {
    return this.user.pkCust
  }
}

export default UserSession
