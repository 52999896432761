import React, { memo, useRef } from 'react'
import PropTypes from 'prop-types'
import useAuth from 'hooks/useAuth'
import Redirect from './Redirect'
import ROUTES from 'config/routes'

type ProtectedPageProps = {
  children: any
  redirectHref?: string
  isGuestOnly?: boolean
  checkOnce?: boolean
}

const ProtectedPageComp = ({
  children,
  redirectHref,
  isGuestOnly = false,
  checkOnce = false,
}: ProtectedPageProps) => {
  const { isAuthLoading, isGuest, isAuthenticated } = useAuth()

  const href = redirectHref || ROUTES.home

  const isLoadedRef = useRef(false)

  if (checkOnce === true && isLoadedRef.current === true) {
    return children
  }

  if (isAuthLoading) {
    return null
  }

  if (isGuest && isGuestOnly === false) {
    return <Redirect href={href} />
  }

  if (isAuthenticated && isGuestOnly === true) {
    return <Redirect href={href} />
  }

  isLoadedRef.current = true

  return children
}

ProtectedPageComp.propTypes = {
  children: PropTypes.any,
  redirectHref: PropTypes.string,
  isGuestOnly: PropTypes.bool,
  checkOnce: PropTypes.bool,
}

const ProtectedPage = memo(ProtectedPageComp)

ProtectedPage.displayName = 'ProtectedPage'

export default ProtectedPage
